<template>
  <main>
    <div class="headline">Login in to ShairMail</div>
    <p class="mt-5">
      Powered by LedgerMail - World’s 1st Decentralized Email Solution.
    </p>

    <v-form
      method="post"
      class="auth-form mb-5"
      v-model="valid"
      ref="userLogin"
      @submit.prevent="submit()"
      lazy-validation
    >
      <!-- <v-text-field
        class="pt-5"
        label="Enter your Password(Seed Words)"
        v-model="mnemonic"
        :rules="passwordRules"
        required
        name="mnemonic"
      >
      </v-text-field> -->
      <v-text-field
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        name="mnemonic"
        v-model="mnemonic"
        label="Enter your Password(Seed Words)"
        class="input-group--focused"
        @click:append="showPassword = !showPassword"
      ></v-text-field>

      <v-btn
        width="150"
        type="submit"
        class="teal accent-4 white--text"
        :disabled="!valid"
        >Login
      </v-btn>
    </v-form>

    <v-divider></v-divider>
    <v-container>
      <span>New user? create an account! </span>
      <router-link to="/auth/signup">Signup</router-link>
    </v-container>
  </main>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      showPassword: false,
      mnemonic: "",
      passwordRules: [
        (v) => !!v || "Password(Seed Words) is required",
        (v) =>
          /^([a-zA-Z]+\s)*[a-zA-Z]+$/.test(v) || "invalid Password(Seed Words)",
      ],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.userLogin.validate())
        this.$store
          .dispatch("AUTH_LOGIN", { mnemonic: this.mnemonic })
          .then(() => {
            this.$router.push("/");
          })
          .catch(() => {
            this.$store.dispatch("ALERT_ERROR", "User does not exist");
          });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>